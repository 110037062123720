.container-center-content{
    // background-image: url('../assets/images/bg_humingbird.png');
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-size: 100px 100px;
    padding: 20px;
    text-align: center;
    height: 530px !important;
    background-color: #f5f5f5 !important;
}
