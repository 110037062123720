.countdown-container{
    margin: 0 auto;
    margin-top: 0px;
    position: relative;
    width: 280px;
    height: 400px;
    .countdown-svg{
        margin: 0 auto;
        // background-color: gray;
        width: 280px;
        height: 280px;
    }
    .countdown-label {
        position: absolute;
        font-size: 36px;
        font-weight: 200;
        width: 280px;
        top: 130px;
        left: 0px;
        &.countdown-label-time{
            top: 98px;
        }
    }
}
